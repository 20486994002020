import React from 'react'
import Link from '../components/Link/Link'
import Nav from '../components/Nav/Nav'
import * as styles from '../styles/default404.module.scss'

const Default404 = () => {

  return (
    <>
    <Nav background="dark" />
    <div className={styles.container}>
      <div>
        <h1>Oops - something went wrong.</h1>
        <p>
          This is not the page your are looking for.
          <br />
          Feel free to head to our homepage or use our navigation to find your correct location.
        </p>
        <Link to="/">VISIT OUR HOMEPAGE</Link>
      </div>
    </div>
    </>
  )
}

export default Default404
